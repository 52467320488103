import React from 'react';
import Layout from '../components/Layout';

const EasyLanguage = () => {
  return (
    <Layout pageTitle="Rückmeldung zur Barrierefreiheit" pageUrl="barriere-melden">
      <section
        className="max-w-screen-lg pr-6 pl-6 m-auto"
        aria-labelledby="page-heading"
      >
        <h1
          id="page-heading"
          className="text-center lg:text-5xl text-2xl font-bold uppercase lg:mb-12 mb-6"
        >
          Rückmeldung zur Barrierefreiheit
        </h1>
        <div className="prose lg:prose-xl max-w-full">
          <h2>Sind Ihnen Mängel beim barrierefreien Zugang zu Inhalten unserer Internetseite aufgefallen?</h2>
          <p>Wenn Sie eine Barriere melden wollen, schreiben Sie uns bitte eine E-Mail an <a href="mailto:internetpost@bundesregierung.de">internetpost@bundesregierung.de</a>.</p>
          <p>Sie können uns auch anrufen oder Ihre Anfrage in Deutscher Gebärdensprache stellen. Die Kontaktdaten erfahren Sie auf der <a href="https://www.bundesregierung.de/breg-de/service/kontakt" target="_blank" rel="nofollow noopener noreferrer">Kontaktseite der Bundesregierung<span className="sr-only"> (öffnet ein neues Fenster)</span></a>.</p>
        </div>
      </section>
    </Layout>
  );
};

export default EasyLanguage;
